:root{
  --primary-color: #B615D1;
  --accent-color: #F37623;
  --danger: #E55858 !important;
  --white: #FFFFFF;
}

* {
  -ms-overflow-style: none;
}

html, body, #root {
  overflow-x: hidden;
}

body {
  overflow: unset !important;
  font-family: Nunito Sans,-apple-system,blinkmacsystemfont,Segoe UI,helvetica,arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;;
  /* font-family: 'Nunito Sans', sans-serif; */
}

.App {
  background-color: #f2f2f2;
  display: flex;
  align-items: center;
  min-height: 100vh;
  padding-bottom: 64px;
  padding-top: 24px;
  overflow-x: hidden !important;
}

input[type='file'] {
  display: none;
}

.file-container {
  width: 40vw;
}

#alert-message {
  z-index: 1;
}

#file-name {
  margin-left: 5px;
  font-style: italic;
  text-overflow: ellipsis;
}

#appbar {
  display: flex;
  justify-content: center;
  padding-top: 20px;
}

.app-item {
  width: 100%;
}

.app-item>div {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.app-item>div>img {
  margin-right: 10px;
}

.app-item>div>span {
  font-weight: 600;
  font-family: 'Nunito Sans', sans-serif;
}

.app-item-on>p {
  color: var(--primary-color);
}

.app-item-off>p {
  color: #c5c5c5;
}

.line {
  position: relative;
  height: 6px;
}

.line-on {
  background-color: var(--primary-color);
}

@media(max-width: 456px){
  .second{
    /* margin-top: 14px; */
  }

  .second > .line{
    /* margin-top: 20px; */
  }

  .app-item>div>span {
    font-size: 12px;
  }
}

@media(max-width: 375px) {
  .app-item>div>span {
    font-size: 9px;
  }
}

.line-off {
  background-color: #f0f8ff;
}

header>img {
  width: 100px;
  margin: 30px 0 15px 25px;
}

.cards-logo {
  text-align: center;
}

.cards-logo > img {
  width: 60px;
  height: 35px;
  margin-left: 13px;
  margin-bottom: 10px;
  object-fit: contain;
}

#bottom-cards {
  margin-bottom: 10px;
}

.forms {
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 20px;
}

#alert-message {
  position: fixed;
  top: 0;
  right: 0;
}

#wrapper {
  background-color: white;
  border: 1px solid #dfd4d4;
  border-radius: 10px;
  width: 39rem;
  margin: auto;
  /* border-radius: 5px; */
  box-shadow: 10px 10px 33px -30px rgba(0,0,0,0.50);
}

.courseClass {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 10px;
  margin-top: 10px;
  width: 96%;
}

.courseClass > div {
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-bottom: 20px;
}

.column {
  display: flex;
  flex-direction: column;
}

.contador {
  background-color: var(--danger);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 100%;
}

.contador > p {
  color: var(--white);
  font-size: 2.25rem;
  font-weight: bold;
  margin-bottom: 0;
  margin-right: 10px;
  min-width: 100px;
}

.contador > span {
  color: var(--white);
  font-family: "Open Sans", sans-serif;
  font-size: .875rem;
  font-weight: 500;
  line-height: 1.5;
  margin-left: 10px;
}

.courseClass > div > div > img {
  height: 100px;
  width: 100px;
  max-height: 100px;
  max-width: 100px;
  margin-right: 10px;
  object-fit: cover;
}

hr {
  width: 96%;
}

.box> div > input, .box > .card > .rowCard > div > input {
  margin-bottom: 10px;
  background-color: #f0f8ff;
  padding: 10px;
  position: relative;
  border: 1px solid #dfd4d4;
  border-radius: 2px;
  width: 100%;
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 200;
}

.box > div > div > input {
  margin-bottom: 10px;
  background-color: #f0f8ff;
  padding: 10px;
  position: relative;
  border: 1px solid #dfd4d4;
  border-radius: 2px;
  width: 100%;
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 200;
}

.box> div >input:focus {
  outline: none;
  background-color: white;
  border: 2px solid var(--primary-color);
  border-radius: 5px;
}

.box > div > .rowPhone {
  display: flex;
  justify-content: space-between;
}

.box> div > div >input:focus {
  outline: none;
  background-color: white;
  border: 2px solid var(--primary-color);
  border-radius: 5px;
}

.box > div > .rowPhone > select{
  display: block;
  font-family: 'Nunito Sans', sans-serif;
  color: #444;
  line-height: 1.3;
  padding: .6em 1.4em .5em .8em;
  width: 18%;
  min-width: 84px;
  box-sizing: border-box;
  border: 1px solid #dfd4d4;
  box-shadow: 0 1px 0 1px rgba(0,0,0,.04);
  border-radius: 2px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #f0f8ff;
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
  background-repeat: no-repeat, repeat;
  background-position: right .7em top 50%, 0 0;
  background-size: .65em auto, 100%;
  margin-bottom: 10px;
  margin-right: 10px;
}

.box> div >.row>input {
  margin-bottom: 10px;
  background-color: #f0f8ff;
  padding: 10px;
  position: relative;
  border: none;
  width: 85%;
}

.box> div >.row>input:focus {
  outline: none;
  background-color: white;
  border: 2px solid var(--primary-color);
  border-radius: 5px;
}

.box > .card {
  display: flex;
  flex-direction: column;
}

.box > .card > .rowCard {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  width: 100%;
}

.rowCard > div {
  width: 48%;
}

.rowCard > div:nth-of-type(2) {
  margin-right: 10px;
}

.box > .card > div > .rccs > div {
  margin: 0 0 0 auto;
  width: 270px;
}

.details {
  margin-top: 20px;
  text-align: left;
  width: 100%;
}

.details > span {
  color: #707780;
  margin-left: 10px;
}

.details > hr {
  margin-top: 0;
  margin-bottom: 10px;
}

.details > div {
  display: flex;
  justify-content: space-between;
  margin-left: 20px;
}

.details > div > p{
  color: #32363b;
  line-height: 1.5;
  width: auto;
}

.details > div > p:nth-child(1) {
  font-family: inherit;
  font-size: .75rem;
  font-weight: 700;
  text-transform: uppercase;
}

.details > div > p:nth-child(2){
  margin-right: 20px;
  text-align: end;
}

#infor-icon {
  width: 20px;
  cursor: pointer;
}

#card-brand {
  width: 50px;
  height: 30px;
  object-fit: contain;
  bottom: 0;
  position: absolute;
}

#loading {
  padding-bottom: 20px;
  margin-top: 20px;
  text-align: center;
}

.modal-content {
  border-radius: .8rem !important;
}

.modal-header {
  border-bottom: none !important;
}

.modal-footer {
  border-top: none !important;
}

.row-input {
  display: inline-flex;
  flex-wrap: wrap;
  width: 100%;
  /* margin-bottom: 10px; */
}

.cvvInput {
  position: relative;
  width: 100%;
}

.cvvInput>input {
  margin-bottom: 10px;
  background-color: #f0f8ff;
  padding: 10px;
  position: relative;
  border: 1px solid #dfd4d4;
  border-radius: 2px;
  width: 100%;
  font-family: 'Nunito Sans', sans-serif;
}

.cvvInput>input:focus {
  outline: none;
  background-color: white;
  border: 2px solid var(--primary-color);
  border-radius: 5px;
}

.cvvInput>img {
  position: absolute;
  right: 15px;
  top: 10px;
}

/* .row-input>div>input {
  border: none;
  width: 13vw;
  background: transparent;
  border-bottom: 1px solid #b8b8b8;
}

.row-input>div>label {
  font-size: 15px;
  font-weight: 500;
} */

form>p {
  margin-top: 0;
  font-size: 20px;
  font-weight: 600;
  color: #b8b8b8;
  font-family: 'Nunito Sans', sans-serif;
}

.confirmButtonContainer {
  margin: 10px 20px;
  min-width: 140px;
}

.confirm-button {
  background-color: var(--primary-color);
  color: white;
  border: none;
  height: 40px;
  width: 100%;
  border-radius: 5px;
  margin-bottom: 25px;
  font-family: 'Nunito Sans', sans-serif;
}

.confirm-button:disabled{
  background-color: #aaa;
  cursor: no-drop;
}

.box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* padding: 16px 10px; */
}

.box > div:nth-child(-n+2) {
  border: 1px solid #dfd4d4;
  border-radius: 10px;
  margin: 6px;
  padding: 10px;
  min-width: 98% !important;
}

.form-container {
  padding-left: 20px;
  padding-right: 20px;
}

.select-container {
  width: 40vw;
}

.select-container > label {
  margin-right: 10px;
}

#success-container {
  padding: 2rem;
  text-align: center;
}

#success-container > img {
  object-fit: contain;
  width: 5vw;
}

#photo-icon {
  background-color: #e7e7e7ad;
  width: 40px;
  height: 25px;
  border: 1px solid #00000059;
  border-radius: 3px;
  cursor: pointer;
}

#titleCardsInfor {
  font-size: 20px;
  text-align: center;
  text-decoration: underline;
  text-decoration-style: dotted;
}

#select-mcc {
  width: 30vw;
}

.add-card {
  margin-left: 20px;
  width: 40vw;
}

.add-card > img {
  width: 25px;
  object-fit: contain;
  margin-right: 5px;
  cursor: pointer;
}

.add-card > span {
  font-weight: 500;
}

.remove-card {
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-end;
}

.remove-card > img {
  width: 25px;
  object-fit: contain;
  margin-right: 5px;
  cursor: pointer;
}

.remove-card > span {
  font-weight: 500;
}

.customSelectContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 45px;
  column-gap: 10px;
  margin-bottom: 15px;
}

.customSelect {
  display: block;
  /* font-size: 16px; */
  font-family: 'Nunito Sans', sans-serif;
  /* font-weight: 700; */
  color: #444;
  line-height: 1.3;
  padding: .6em 1.4em .5em .8em;
  width: 100%;
  box-sizing: border-box;
  /* margin: 0 15px 0 0; */
  border: 1px solid #dfd4d4;
  box-shadow: 0 1px 0 1px rgba(0,0,0,.04);
  border-radius: 2px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #f0f8ff;
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
  background-repeat: no-repeat, repeat;
  /* arrow icon position (1em from the right, 50% vertical) , then gradient position*/
  background-position: right .7em top 50%, 0 0;
  /* icon size, then gradient */
  background-size: .65em auto, 100%;
}
/* Hide arrow icon in IE browsers */
.customSelect::-ms-expand {
  display: none;
}
/* Hover style */
.customSelect:hover {
  border-color: #888;
}
/* Focus style */
.customSelect:focus {
  border-color: #aaa;
  /* It'd be nice to use -webkit-focus-ring-color here but it doesn't work on box-shadow */
  box-shadow: 0 0 1px 3px rgba(59, 153, 252, .7);
  box-shadow: 0 0 0 3px -moz-mac-focusring;
  color: #222; 
  outline: none;
}

.footer {
  margin-left: 20px;
}

.footer > p{
  color: #999;
  font-size: 10px;
  font-weight: 400;
}

@media (max-width: 680px) {
  .App {
    background-color: white;
    display: block;
  }

  #select-mcc {
    width: 50vw;
  }

  #wrapper {
    width: 98%;
    max-width: 670px;
  }

  .confirmButtonContainer {
    margin: 10px 20px;
  }

  .confirm-button {
    height: 50px;
    width: 100%;
  }

  #input-form {
    min-width: 140px;
  }

  #input-form>input {
    width: 50vw;
  }

  .file-container {
    width: 50vw;
  }

  .select-container {
    width: 50vw;
  }

  .cards-logo > img {
    width: 40px;
    height: 25px;
  }

  #input-brand {
    width: 40vw !important;
    margin-right: 5px;
  }

  #success-container > img {
    width: 10vw;
  }

  .add-card {
    width: 51vw;
  }

  .box > div:nth-child(-n+2) {
    width: 90%;
  }

  .box > .card > div:nth-of-type(2) > input {
    width: 100%;
  }

  .box > .details {
    width: 90%;
  }
}

@media (max-width: 619px) {
  .box > .card > .rowCard {
    flex-direction: column;
  }

  .rowCard > div {
    width: 100%;
  }

  .rowCard > div:nth-of-type(2) {
    margin-right: 0;
  }
}

.courseInfoContainer{
  padding: 0 16px 0 16px;
}

.courseInfoGroup{
  font-size: .9rem;
  font-family: Helvetica, sans-serif;
}

.courseInfoGroup strong {
  font-weight: 500;
  font-size: .8rem;
  color: #555;
}

.courseInfoGroup p{
  font-size: 1.2rem;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 0;
  text-transform: uppercase;
}

.courseInfoGroup > .primary {
  color: var(--primary-color);
}

.inputGroup {
  align-self: flex-start;
}

.inputGroup label {
  margin-left: 8px;
  font-size: .9rem;
  color: #212121;
  font-family: 'Nunito Sans', sans-serif;
}

.backButton {
  background-color: var(--primary-color);
  color: white;
  padding: .2em .5em .2em .5em;
  border: none;
  border-radius: .2em;
  margin-left: .8em;
  margin-top: 1em;
  transition: .2s;
}

.backButton:hover{
  filter: brightness(.9);
}

.row {
  display: flex !important;
  flex-direction: row !important;
  align-items: center;
  justify-content: space-between;
}

.taxpayer-container {
  width: 100%;
}

.taxpayer-container input {
  width: 100%;
}

.taxpayer-container button {
  width: 100%;
}

.button-group {
  width: 100%;
}

.button-group button {
  width: 45%;
}

.button-group button.secondary {
  background-color: transparent;
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
}

.button-group button:hover {
  filter: brightness(0.9);
}